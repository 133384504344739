import { Component } from '@angular/core';

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  children?: ChildrenItems[];
  permission?: string;
  description?: string;
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab?: string;
  type?: string;
  permission?: string;
  icontype: string;
  description?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Inicio',
    type: 'link',
    icontype: 'nc-icon nc-bank',
    description: 'Dashboard'
  },

  {
    path: '/catalogos/clientes',
    title: 'Clientes',
    type: 'link',
    icontype: 'fal fa-user-tag',
    permission: "CLIENTES",
    description: 'Clientes'
  },
  {
    path: '/catalogos/terrenos',
    title: 'Terrenos',
    type: 'link',
    icontype: 'fal fa-seedling',
    permission: "TERRENOS",
    description: 'Terrenos'
  },
  {
    path: '/catalogos/metricas',
    title: 'Metricas',
    type: 'link',
    icontype: 'fal fa-chart-line',
    permission: "METRICAS",
    description: 'Métricas'
  },
  {
    path: '/catalogos/subirImagenes',
    title: 'Subir Imagenes',
    type: 'link',
    icontype: 'fal fa-upload',
    permission: "USUARIOS",
    description: 'Subir imagenes predeterminadas'
  },
  {
    path: '/catalogos/banners',
    title: 'Banners',
    type: 'link',
    icontype: 'fal fa-chalkboard',
    permission: "BANNERS",
    description: 'Banners'
  },
  {
    path: '/catalogos/notificaciones',
    title: 'Notificaciones',
    type: 'link',
    icontype: 'fal fa-bell-on',
    permission: "NOTIFICACIONES",
    description: 'Notificaciones'
  },
  {
    path: '/catalogos/usuarios',
    title: 'Usuarios',
    type: 'link',
    icontype: 'fal fa-users',
    permission: "USUARIOS",
    description: 'Usuarios'
  },


  // {
  //   path: '/catalogos',
  //   title: 'Catálogos',
  //   type: 'sub',
  //   icontype: 'fal fa-archive',
  //   children: [
  //     // { path: 'clientes', title: 'Clientes', icontype: 'fal fa-user-tag', permission: "USUARIOS", description: 'Clientes' },
  //     // { path: 'arrendamientos', title: 'Terrenos', icontype: 'fal fa-seedling', permission: "USUARIOS", description: 'Terrenos' },
  //     // { path: 'usuarios', title: 'Usuarios', icontype: 'fal fa-users', permission: "USUARIOS", description: 'Usuarios' },
  //     // { path: 'metricas', title: 'Métricas', icontype: 'fal fa-chart-line', permission: "USUARIOS", description: 'Métricas' }

  //   ]
  // }
];

@Component({

  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent {
  public menuItems: any[];
  isNotMobileMenu() {
    if (window.outerWidth > 991) {
      return false;
    }
    return true;
  }
  private token = JSON.parse(localStorage.getItem('jwtToken'));
  public perm = this.token.user.permisos;
  public user = this.token.user;

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
}
