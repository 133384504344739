import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Globals } from "./../globals";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToastrManager } from "ng6-toastr-notifications";
@Injectable({
  providedIn: "root",
})
export class UsuariosService {
  public usuario;
  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router,
    private toastr: ToastrManager
  ) {}

  get(filters: any = {}) {
    filters = JSON.stringify(filters);
    return this.http
      .get(`${this.globals.apiUrl}/usuarios/get`, { params: { filters } })
      .pipe(catchError(this.handleError));
  }
  getInfo(filters: any = {}) {
    filters = JSON.stringify(filters);
    return this.http
      .get(`${this.globals.apiUrl}/infoUsuarios/get`, { params: { filters } })
      .pipe(catchError(this.handleError));
  }
  getAll() {
    return this.http
      .get(`${this.globals.apiUrl}/usuarios/getAll`)
      .pipe(catchError(this.handleError));
  }

  getAllClientes(suscripcionActiva, page, limit) {
    return this.http
      .get(`${this.globals.apiUrl}/usuarios/getAllClientes`, {
        params: {
          suscripcionActiva: suscripcionActiva,
          page: page,
          limit: limit,
        },
      })
      .pipe(catchError(this.handleError));
  }

  agregar(usuario) {
    return this.http
      .post(`${this.globals.apiUrl}/usuarios/agregar`, {
        usuario: usuario,
      })
      .pipe(catchError(this.handleError));
  }

  editar(usuario) {
    return this.http
      .post(`${this.globals.apiUrl}/usuarios/editar`, {
        usuario: usuario,
      })
      .pipe(catchError(this.handleError));
  }

  eliminar(id) {
    return this.http
      .post(`${this.globals.apiUrl}/usuarios/eliminar`, {
        id: id,
      })
      .pipe(catchError(this.handleError));
  }

  guardarPermisos(usuario) {
    return this.http
      .post(`${this.globals.apiUrl}/usuarios/permisos`, {
        usuario: usuario,
      })
      .pipe(catchError(this.handleError));
  }

  changePassword(usuario, oldPwd, newPwd, isFromAdminPanel) {
    return this.http
      .post(`${this.globals.apiUrl}/usuarios/changePassword`, {
        usuario: usuario,
        password: oldPwd,
        newPassword: newPwd,
        isFromAdminPanel: isFromAdminPanel,
      })
      .pipe(catchError(this.handleError));
  }

  signOut() {
    localStorage.removeItem("jwtToken");
    this.router.navigate(["/login"]);
  }

  isLoggedIn() {
    if (localStorage.getItem("jwtToken")) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("jwtToken")).user;
  }

  getToken() {
    return JSON.parse(localStorage.getItem("jwtToken")).token;
  }
  showToastr(tipo, mensaje) {
    switch (tipo) {
      case "success":
        this.toastr.successToastr(mensaje, "", {
          maxShown: 1,
          animate: "slideFromBottom",
        });
        break;
      case "error":
        this.toastr.errorToastr(mensaje, "Error", {
          maxShown: 1,
          animate: "slideFromBottom",
        });
        break;
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
