import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  apiUrl: string = 'https://admin.buscarancho.com';
  permisosdisponibles = [
    'DASHBOARD',
    'CLIENTES',
    'TERRENOS',
    'USUARIOS',
    'METRICAS',
    'BANNERS',
    'NOTIFICACIONES',
  ];
}
