
import {filter} from 'rxjs/operators';
import { Component, OnInit, Renderer, ViewChild, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { UsuariosService } from '../../services/usuarios.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';

var misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
}

@Component({

  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  private _router: Subscription;
  private username: any[];
  changePwdForm: FormGroup;
  disableButtons = false;
  @ViewChild("navbar-cmp") button;

  constructor(
    private usuariosService: UsuariosService,
    location: Location,
    private renderer: Renderer,
    private element: ElementRef,
    private router: Router,
    public modalService: NgxSmartModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrManager
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    this.changePwdForm = this.formBuilder.group({
      oldPwd: ['', Validators.required],
      newPwd: ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
      newPwdConfirm: ['', [Validators.required]]
    }, {
      validator: this.passwordMatchValidator
   });
    this.username = JSON.parse(localStorage.getItem('jwtToken')).user.info[0].nombre;
    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    }
    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const $layer = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
      }
    });


  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;

    } else {
      setTimeout(function () {
        body.classList.add('sidebar-mini');

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  isMobileMenu() {
    if (window.outerWidth < 991) {
      return false;
    }
    return true;
  }

  sidebarOpen() {
    var toggleButton = this.toggleButton;
    var html = document.getElementsByTagName('html')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    html.classList.add('nav-open');
    this.sidebarVisible = true;
  }

  sidebarClose() {
    var html = document.getElementsByTagName('html')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];

    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
  }

  sidebarToggle() {
    if (this.sidebarVisible == false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(2);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      var parent = this.listTitles[item];
      if (parent.path === titlee) {
        return parent.description;
      } else if (parent.children) {
        var children_from_url = titlee.split("/")[2];
        for (var current = 0; current < parent.children.length; current++) {
          if (parent.children[current].path === children_from_url) {
            return parent.children[current].description;
          }
        }
      }
    }
    return 'Dashboard';
  }

  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  signOut() {
    this.usuariosService.signOut();
  }

  openChangePassword() {
    this.modalService.get('changePassword').open();
  }

  changePassword() {
    this.disableButtons = true;
    let formObj = this.changePwdForm.value;
    let usuario = JSON.parse(localStorage.getItem('jwtToken')).user;

    this.usuariosService.changePassword(usuario.id, formObj.oldPwd, formObj.newPwd, true)
    .subscribe(
      response => {
        this.toastr.successToastr('Se cambió tu contraseña correctamente.', 'Success', {maxShown: 1, animate: 'slideFromBottom'});
        this.modalService.getModal('changePassword').close();
        this.disableButtons = false;
        this.changePwdForm.reset();
      },
      err => {
        this.toastr.errorToastr(err, 'Error', {maxShown: 1, animate: 'slideFromBottom'});
        this.disableButtons = false;
      }
    );
  }

  passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('newPwd').value; // get password from our password form control
    const confirmPassword: string = control.get('newPwdConfirm').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('newPwdConfirm').setErrors({ NoPassswordMatch: true });
    }
  }

  get f() { return this.changePwdForm.controls; } // accesar a los controles del form
}
